import { Component } from "@angular/core";
import { NgComponentOutlet } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { GridService } from "../activity-grid/services";
import { Filter } from "./models";
import { FiltersService } from "./services";
import { IconButtonComponent } from "@shared/components";
import { enterLeaveFadeInOutAnimation } from "@shared/animations";

@Component({
  selector: "ath-activity-filter",
  standalone: true,
  imports: [NgComponentOutlet, IconButtonComponent, MatMenuModule, MatButtonModule, MatIconModule],
  templateUrl: "./activity-filter.component.html",
  styleUrl: "./activity-filter.component.scss",
  animations: [enterLeaveFadeInOutAnimation],
})
export class ActivityFilterComponent {
  selectedFilters = this.filterService.activeFilters;
  inactiveFilters = this.filterService.inactiveFilters;
  canApplyFilters = this.filterService.canApplyFilters;

  constructor(
    private filterService: FiltersService,
    private gridService: GridService
  ) {}

  activateFilter(filter: Filter, event: Event): void {
    event.stopPropagation();
    this.filterService.toggleFilter(filter.id);
  }

  applyFilters(): void {
    if (this.filterService.applyFilters()) {
      this.closeDrawer();
    }
  }

  clearAllFilters(): void {
    this.filterService.clearAllFilters();
  }

  closeDrawer(): void {
    void this.gridService.filterDrawer?.close();
  }
}
