import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { GridService } from "../activity-grid/services";
import { MatButtonModule } from "@angular/material/button";
import { AuthService } from "@shared/services";

@Component({
  selector: "ath-no-active-filters",
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: "./no-active-filters.component.html",
  styleUrl: "./no-active-filters.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoActiveFiltersComponent {
  private gridService = inject(GridService);
  private authService = inject(AuthService);
  public name = this.authService.currentAccount().name;

  openFilterDrawer(): void {
    void this.gridService.filterDrawer?.open();
  }
}
