import { ApplicationConfig } from "@angular/core";
import {
  RouteReuseStrategy,
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
} from "@angular/router";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { provideAnimations } from "@angular/platform-browser/animations";
import { routes } from "./app.routes";
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from "@azure/msal-angular";
import { mockBackendInterceptor } from "./interceptors/mocks/mock-backend.interceptor";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MsalInterceptorConfigFactory,
} from "./msal-factories";
import { PageTitleStrategy, ReuseStrategy } from "./route-strategies";
import { idTokenInterceptor } from "./interceptors/id-token.interceptor";

const FORM_OPTIONS = {
  appearance: "outline",
  floatLabel: "always",
  subscriptSizing: "dynamic",
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
      withInterceptors([mockBackendInterceptor, idTokenInterceptor])
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideAnimations(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { ...FORM_OPTIONS } },
    { provide: RouteReuseStrategy, useClass: ReuseStrategy },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
  ],
};
