import { Component, computed, inject } from "@angular/core";
import { GridService, GridState } from "@src/activity/activity-grid/services";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { NgTemplateOutlet } from "@angular/common";

@Component({
  standalone: true,
  selector: "ath-activity-header",
  templateUrl: "./activity-header.component.html",
  styleUrls: ["./activity-header.component.scss"],
  imports: [MatChipsModule, MatIconModule, MatButtonModule, NgTemplateOutlet],
})
export class ActivityHeaderComponent {
  private gridService = inject(GridService);
  private gridState = this.gridService.gridState;

  readonly GridState = GridState;
  readonly recordsCollection = this.gridService.records;

  secondaryTitleValue = computed(() => {
    return this.gridState() === GridState.Loading
      ? "Searching for records..."
      : "Your search returned " + this.recordsCollection().totalCount + " records.";
  });

  readonly templateContexts = this.gridService.templateContexts;

  openFilterDrawer(): void {
    void this.gridService.filterDrawer?.open();
  }
}
