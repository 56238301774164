import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

@Injectable({ providedIn: "root" })
export class ReuseStrategy implements RouteReuseStrategy {
  private routesToStore: string[] = ["activity"];
  private storedRouteInstances = new Map<string, DetachedRouteHandle>();

  // Stored instead of destroyed
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig?.path && this.routesToStore.includes(route.routeConfig.path);
  }

  // Called if shouldDetach returns true
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (!route.routeConfig?.path || !handle) {
      return;
    }

    this.storedRouteInstances.set(route.routeConfig.path, handle);
  }

  // Should the component be re-used or not
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig?.path) {
      return false;
    }

    return this.storedRouteInstances.has(route.routeConfig.path);
  }

  // Called if shouldAttach returns true
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = route.routeConfig?.path;

    return (path && this.storedRouteInstances.get(path)) ?? null;
  }

  // Called for every route change
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig?.path === curr.routeConfig?.path;
  }
}
