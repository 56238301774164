<ng-template #pillTemplate let-details="details">
  <span>{{ details }}</span>
</ng-template>

@let selection = selectedUsers();

<ath-filter-base [filter]="filter()">
  <div class="content">
    <mat-form-field class="flex-1">
      <mat-chip-grid #chipGrid aria-label="User selection">
        @for (user of selection; track user.id) {
          <mat-chip-row (removed)="removeUser(user)">
            {{ user.name }}

            <button matChipRemove [attr.aria-label]="'remove ' + user.name">
              <mat-icon svgIcon="delete" />
            </button>
          </mat-chip-row>
        } @empty {
          <mat-chip highlighted>No selection. All users are included.</mat-chip>
        }
      </mat-chip-grid>

      <input
        name="currentUser"
        placeholder="Click and type to find users"
        [formControl]="userQuery"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="keyCodes"
        #inputField
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addUser($event)"
        hideSingleSelectionIndicator
      >
        @for (user of filteredUsers$ | async; track user.id) {
          <mat-option [value]="user">{{ user.name }}</mat-option>
        } @empty {
          <mat-option disabled>No users found</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ath-filter-base>
