import { Pipe, type PipeTransform } from "@angular/core";
import { Account } from "@shared/models";

@Pipe({
  name: "athRecipientsFormatter",
  standalone: true,
})
export class RecipientsFormatterPipe implements PipeTransform {
  transform(recipients: Account[]): string {
    return recipients.map((x) => x.value).join(", ");
  }
}
