import { computed, Injectable, signal } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { Filter, FilterState, PillTemplateContext } from "../models";
import { FILTERS } from "../filters";

export interface CustomParam {
  key: string;
  values: string[];
}

interface StateAndParam {
  state: FilterState;
  createHttpParam: () => CustomParam | undefined;
}

export type FilterData = Map<number, StateAndParam>;

export interface ApplyFilterData {
  filters: FilterData;
  templateContexts: PillTemplateContext[];
}

@Injectable()
export class FiltersService {
  private filters = signal<Filter[]>(FILTERS);
  private filterValues = signal<FilterData>(new Map());

  canApplyFilters = computed(() => {
    if (this.filterValues().size === 0) {
      return false;
    }

    return ![...this.filterValues().values()].some((x) => !x.state.isStateValid);
  });
  activeFilters = computed(() => this.filterDisplayed(this.filters()));
  inactiveFilters = computed(() => this.filterInactive(this.filters()));
  currentlyAppliedFilters$ = new ReplaySubject<ApplyFilterData>(1);
  clearAllFilters$ = new Subject<void>();

  private filterDisplayed(filter: Filter[]): Filter[] {
    return filter.filter((filter) => filter.required || filter.active);
  }

  private filterInactive(filter: Filter[]): Filter[] {
    return filter.filter((filter) => !filter.required && !filter.active);
  }

  toggleFilter(id: number): void {
    this.filters.update((filters: Filter[]) => {
      const currentFilter = filters.find((filter) => filter.id === id);

      if (currentFilter) {
        currentFilter.active = !currentFilter.active;
      }

      return [...filters];
    });
  }

  updateFilterValue(
    id: number,
    value: FilterState,
    createHttpParam: () => CustomParam | undefined
  ): void {
    this.filterValues.update((values) => {
      values.set(id, { state: value, createHttpParam });

      return new Map(values);
    });
  }

  clearFilterValue(id: number): void {
    this.filterValues.update((values) => {
      values.delete(id);

      return new Map(values);
    });
  }

  isFilterValid(id: number): boolean {
    if (!this.filterValues().has(id)) {
      return true;
    }

    return this.filterValues().get(id)?.state.isStateValid ?? false;
  }

  clearAllFilters(): void {
    this.clearAllFilters$.next();
  }

  applyFilters(): boolean {
    if (this.canApplyFilters()) {
      const data = this.getFiltersAndContexts();
      this.currentlyAppliedFilters$.next(data);
      return true;
    }

    return false;
  }

  private getFiltersAndContexts(): ApplyFilterData {
    return {
      filters: this.filterValues(),
      templateContexts: [...this.filterValues().values()]
        .filter((x) => x.state.templateContext)
        .map((x) => x.state.templateContext!),
    };
  }
}
