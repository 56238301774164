import type { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { AuthService } from "@shared/services";

export const idTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const account = authService.currentAccount;
  const idToken = account().idToken;

  if (!idToken) {
    // Sign user out || redirect to login page
    // throw new Error("Unable to add required authorization header.");
    authService.login();
  }

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  return next(authReq);
};
