import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { AuthService } from "@shared/services";

@Component({
  selector: "ath-logged-out-component",
  templateUrl: "./logged-out.component.html",
  styleUrl: "./logged-out.component.scss",
  imports: [MatButtonModule],
  standalone: true,
})
export class LoggedOutComponent {
  private authService = inject(AuthService);

  login(): void {
    this.authService.login();
  }
}
