import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "@src/environments/environment";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.externalIdConfig.clientId,
      authority: environment.externalIdConfig.authority,
      redirectUri: "/",
      postLogoutRedirectUri: "/logged-out",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      secureCookies: true,
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback: (_, message: string): void => {
          if (!environment.deployed) {
            console.info(message);
          }
        },
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.externalIdConfig.scopes],
    },
    loginFailedRoute: "/login-failed",
  };
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    [environment.externalIdConfig.uri, environment.externalIdConfig.scopes],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
