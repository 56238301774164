import {
  Component,
  effect,
  ElementRef,
  HostBinding,
  signal,
  untracked,
  viewChild,
} from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { CommonModule } from "@angular/common";
import { IconName } from "@shared/constants";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { navbarTransitionAnimation } from "@shared/animations";
import { AuthService } from "@shared/services";
import { MatTooltipModule } from "@angular/material/tooltip";

interface NavItem {
  name: string;
  route: string;
  icon: IconName;
}

@Component({
  selector: "ath-navbar",
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: "./navbar.component.html",
  styleUrl: "./navbar.component.scss",
  animations: [navbarTransitionAnimation],
})
export class NavbarComponent {
  @HostBinding("@navbarTransitionAnimation") get animationState(): {
    value: boolean;
    params: { startWidth: number };
  } {
    return {
      value: this.isExpanded(),
      params: { startWidth: this.startWidth },
    };
  }

  nav = viewChild.required<ElementRef<HTMLElement>>("nav");
  isExpanded = signal(false);
  startWidth = 0;

  activeOptions = {
    exact: true,
  };

  navItems: NavItem[] = [{ name: "Activity", route: "/activity", icon: "call-log" }];

  navItems2: NavItem[] = [
    { name: "Audits", route: "/audit", icon: "receipt-long" },
    // { name: "Settings", route: "/non-existing", icon: "settings" },
    // { name: "Help", route: "/non-existing", icon: "help" },
  ];

  constructor(private authService: AuthService) {
    effect(() => {
      this.isExpanded();

      untracked(() => {
        this.setStartWidth();
      });
    });
  }

  setStartWidth(): void {
    this.startWidth = this.nav().nativeElement.clientWidth;
  }

  toggleExpand(): void {
    this.isExpanded.set(!this.isExpanded());
  }

  initiateLogout(): void {
    this.authService.logout();
  }
}
