import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { Column } from "@src/activity/models";

@Component({
  selector: "ath-activity-grid-options-dialog",
  standalone: true,
  imports: [MatDialogModule, CdkDropList, CdkDrag, MatIconModule, MatButtonModule],
  templateUrl: "./activity-grid-options-dialog.component.html",
  styleUrl: "./activity-grid-options-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityGridOptionsDialogComponent {
  private readonly columns = inject<Column[]>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef<ActivityGridOptionsDialogComponent>);

  columnOrder: Column[] = this.columns.map((column) => ({ ...column }));

  drop(event: CdkDragDrop<Column[]>): void {
    const staticColumnsCount = this.columnOrder.filter((column) => column.static).length - 1;

    if (this.columns[event.currentIndex + staticColumnsCount].static) {
      return;
    }

    moveItemInArray(
      this.columnOrder,
      event.previousIndex + staticColumnsCount,
      event.currentIndex + staticColumnsCount
    );
  }

  toggleColumnVisibility(column: Column): void {
    if (column.static) {
      return;
    }

    column.active = !column.active;
  }

  close(): void {
    this.dialogRef.close();
  }
}
