@let state = gridState();

@if (state === GridState.Loading || state === GridState.Empty) {
  <div class="overlaying-container">
    @switch (state) {
      @case (GridState.Loading) {
        <ath-loading-ripple />
      }
      @case (GridState.Empty) {
        <ath-activity-grid-no-results />
      }
    }
  </div>
}

<div class="table-wrapper">
  <table
    #table
    mat-table
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="reorderColumn($event)"
    [dataSource]="recordsCollection().values"
  >
    <ng-container matColumnDef="kind">
      <th mat-header-cell *matHeaderCellDef cdkDrag>Type</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon [svgIcon]="row.kind === 'audio' ? 'call' : 'chat'" />
      </td>
    </ng-container>

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef cdkDrag>Source</th>
      <td mat-cell *matCellDef="let row">{{ row.source.name }}</td>
    </ng-container>

    <ng-container matColumnDef="sender">
      <th mat-header-cell *matHeaderCellDef cdkDrag>From</th>
      <td mat-cell *matCellDef="let row">{{ row.sender.value }}</td>
    </ng-container>

    <ng-container matColumnDef="recipients">
      <th mat-header-cell *matHeaderCellDef cdkDrag>To</th>
      <td mat-cell *matCellDef="let row">{{ row.recipients | athRecipientsFormatter }}</td>
    </ng-container>

    <ng-container matColumnDef="callStart">
      <th mat-header-cell *matHeaderCellDef cdkDrag>Start time</th>
      <td mat-cell *matCellDef="let row">
        {{ row.recordedAt | date: "dd MMM yyyy HH:mm:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef cdkDrag>Duration</th>
      <td mat-cell *matCellDef="let row">{{ row.duration?.toString() }}</td>
    </ng-container>

    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef cdkDrag>User</th>
      <td mat-cell *matCellDef="let row">{{ row.account.owner }}</td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef cdkDrag cdkDragDisabled></th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-flat-button
          type="button"
          class="open-button"
          [disabled]="!row.hasFile"
          (click)="openRecording(row.id)"
        >
          {{ "Open" }}
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns(); sticky: true"
      class="table-header"
    ></tr>
    <tr class="row" mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
  </table>
</div>

<mat-toolbar>
  <mat-toolbar-row>
    <ath-activity-grid-options
      [columns]="columns()"
      (columnsChange)="updateColumns($event)"
    ></ath-activity-grid-options>
    <mat-paginator
      [length]="recordsCollection().totalCount"
      [pageSizeOptions]="pageSizeOptions"
      (page)="paginatorUpdated($event)"
      showFirstLastButtons
      aria-label="Select page of records"
    >
    </mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>
