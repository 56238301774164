<div class="header">
  <div>
    <h1>Filters</h1>
    <p>Use filters to narrow search results</p>
  </div>
  <ath-icon-button icon="close" (click)="closeDrawer()" />
</div>

<div class="filters">
  @for (filter of selectedFilters(); track filter.id) {
    <ng-container *ngComponentOutlet="filter.component; inputs: { filter: filter }" />
  }
</div>

@if (inactiveFilters().length) {
  <button
    @enterLeaveFadeInOutAnimation
    mat-fab
    extended
    [matMenuTriggerFor]="filterSelectorMenu"
    class="fab-btn"
  >
    <mat-icon svgIcon="add" />
    Filter
  </button>
}

<div class="footer">
  <button mat-button (click)="clearAllFilters()">Reset filters</button>
  <button
    class="apply-filters-btn"
    mat-flat-button
    [disabled]="!canApplyFilters()"
    (click)="applyFilters()"
  >
    Apply filters
  </button>
</div>

<mat-menu #filterSelectorMenu="matMenu" yPosition="above">
  <ng-template matMenuContent>
    @for (filter of inactiveFilters(); track filter.id) {
      <button mat-menu-item (click)="activateFilter(filter, $event)">{{ filter.name }}</button>
    } @empty {
      <span mat-menu-item disabled>All filters active</span>
    }
  </ng-template>
</mat-menu>
