<h2 mat-dialog-title>Grid Options</h2>

<mat-dialog-content>
  <div class="dialog-content" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (column of columnOrder; track column.key) {
      @if (!column.static) {
        <div
          class="column-drag-container"
          [class.inactive]="!column.active"
          (keypress)="toggleColumnVisibility(column)"
          tabindex="0"
          cdkDrag
          [cdkDragDisabled]="column.static"
        >
          <div class="column-drag-item">
            <mat-icon svgIcon="drag-indicator" />
            <span>{{ column.displayName }} </span>
          </div>

          <button mat-icon-button class="visibility-icon" (click)="toggleColumnVisibility(column)">
            <mat-icon [svgIcon]="column.active ? 'visibility' : 'visibility-off'" />
          </button>
        </div>
      }
    } @empty {
      <p>No columns</p>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions">
  <button mat-button class="secondary-button" (click)="close()">Cancel</button>
  <button mat-flat-button [mat-dialog-close]="columnOrder">Apply</button>
</mat-dialog-actions>
