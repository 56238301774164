import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CountedValues } from "@shared/models";
import { map, Observable } from "rxjs";
import { API_BASE_URL } from "@shared/constants";

@Injectable()
export class FilterValueService<T> {
  constructor(private http: HttpClient) {}

  getFilterValues(endpoint: string): Observable<T[]> {
    if (!endpoint) {
      throw new Error(`No registered values endpoint for filter with value endpoint: ${endpoint}`);
    }

    return this.http
      .get<CountedValues<T>>(`${API_BASE_URL}/${endpoint}`)
      .pipe(map((countedValues) => countedValues.values));
  }
}
