import {
  Component,
  input,
  OnInit,
  DestroyRef,
  viewChildren,
  viewChild,
  TemplateRef,
} from "@angular/core";
import {
  Validators,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  NonNullableFormBuilder,
} from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { KeyValuePipe } from "@angular/common";
import { format, startOfMinute } from "date-fns";
import {
  DatePickerComponent,
  MIN_SUPPORTED_DATE,
  TimePickerChangeEvent,
  TimePickerComponent,
} from "@shared/components";
import { DateAndTimeFilter, Filter } from "../../models";
import { FilterErrorContentDirective } from "../filter-base/filter-error-content.directive";
import { DateFilterService } from "./date-filter.service";
import { FilterBase, FilterBaseComponent, FilterBaseHandler } from "../filter-base";

interface DateForm {
  start: FormControl<Date>;
  end: FormControl<Date>;
}

export interface DateFilterContext {
  details: {
    start: { date: string; time: string };
    end: { date: string; time: string };
  };
}

const createStartBeforeEndValidator = (defaultEndDate: Date): ValidatorFn => {
  return (group: AbstractControl<{ start: Date; end: Date }>): ValidationErrors | null => {
    const start = group.value.start;
    const end = group.value.end;

    if (startOfMinute(start) >= startOfMinute(end)) {
      return {
        startAfterEnd: "Start time needs to be earlier than end time",
      };
    }

    if (end > defaultEndDate) {
      return { endInFuture: "End time can't be in the future" };
    }

    if (start < MIN_SUPPORTED_DATE) {
      return {
        startTooEarly: `Start time can't be before ${format(MIN_SUPPORTED_DATE, "dd LLL yyyy")}`,
      };
    }

    return null;
  };
};

@Component({
  selector: "ath-date-filter",
  templateUrl: "./date-filter.component.html",
  styleUrls: ["./date-filter.component.scss"],
  imports: [
    DatePickerComponent,
    ReactiveFormsModule,
    FilterBaseComponent,
    TimePickerComponent,
    FilterErrorContentDirective,
    KeyValuePipe,
  ],
  providers: [DateFilterService],
})
export class DateFilterComponent extends FilterBase implements OnInit {
  protected filter = input.required<Filter>();

  private filterHandler: FilterBaseHandler<DateAndTimeFilter>;
  private startTime: TimePickerChangeEvent | undefined;
  private endTime: TimePickerChangeEvent | undefined;
  private timePickers = viewChildren(TimePickerComponent);
  private pillTemplate = viewChild.required<TemplateRef<unknown>>("pillTemplate");

  defaultStartDate = this.handlingService.defaultStartDate;
  defaultEndDate = this.handlingService.defaultEndDate;

  dateForm = this.fb.group<DateForm>(
    {
      start: this.fb.control(this.defaultStartDate, [Validators.required]),
      end: this.fb.control(this.defaultEndDate, [Validators.required]),
    },
    { validators: [createStartBeforeEndValidator(this.defaultEndDate)] }
  );

  constructor(
    private fb: NonNullableFormBuilder,
    private destroyRef: DestroyRef,
    private handlingService: DateFilterService
  ) {
    super();
    this.filterHandler = this.initializeFilterHandler();

    const clearSub = this.filterHandler.filterCleared$.subscribe(() => {
      this.timePickers().forEach((timePicker) => timePicker.reset());
      this.dateForm.reset();
    });

    this.destroyRef.onDestroy(() => {
      clearSub.unsubscribe();
    });
  }

  private initializeFilterHandler(): FilterBaseHandler<DateAndTimeFilter> {
    return new FilterBaseHandler<DateAndTimeFilter>(
      (state) => this.validatorFn(state!),
      () => ({
        key: "Interval",
        values: [
          this.handlingService.formatUTCInterval(
            this.dateForm.controls.start.value,
            this.dateForm.controls.end.value
          ),
        ],
      }),
      () =>
        this.handlingService.createTemplateContext(
          this.dateForm.controls.start.value,
          this.dateForm.controls.end.value,
          this.pillTemplate
        )
    );
  }

  private validatorFn = (state: DateAndTimeFilter): boolean => {
    if (startOfMinute(state.start) >= startOfMinute(state.end)) {
      return false;
    }

    if (state.end > this.defaultEndDate) {
      return false;
    }

    if (state.start < MIN_SUPPORTED_DATE) {
      return false;
    }

    return true;
  };

  ngOnInit(): void {
    this.filterHandler.initialize(this.dateForm.getRawValue(), this.filter());
  }

  onStartDateChange(date: Date | null): void {
    if (this.startTime && date) {
      this.handlingService.setDateTime(date, this.startTime);
    }

    this.updateExternalState({ start: date as Date });
  }

  onEndDateChange(date: Date | null): void {
    if (this.endTime && date) {
      this.handlingService.setDateTime(date, this.endTime);
    }

    this.updateExternalState({ end: date as Date });
  }

  onTimeChange(time: TimePickerChangeEvent, type: "start" | "end"): void {
    if (!this.dateForm.controls[type].value) {
      return;
    }

    if (type === "start") {
      this.startTime = time;
    } else {
      this.endTime = time;
    }

    const tempDate = new Date(this.dateForm.controls[type].value);
    this.handlingService.setDateTime(tempDate, time);

    this.dateForm.controls[type].patchValue(tempDate);
    this.updateExternalState({ [type]: tempDate });
  }

  private updateExternalState(currState: Partial<DateAndTimeFilter>): void {
    this.filterHandler.updateState(currState);
  }
}
