import { Injectable } from "@angular/core";
import { TZDate } from "@date-fns/tz";
import { UTCDate } from "@date-fns/utc";

@Injectable({
  providedIn: "root",
})
export class DateService {
  readonly timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  convertToUTC(date: Date): UTCDate {
    return new UTCDate(date);
  }

  convertUTCToTz(date: Date, tz?: string): Date {
    return tz ? new TZDate(date, tz) : new TZDate(date, this.timeZone);
  }
}
