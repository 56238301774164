import { HttpResponse, type HttpInterceptorFn } from "@angular/common/http";
import { catchError, of } from "rxjs";
import { Customer } from "@shared/services";

export const mockBackendInterceptor: HttpInterceptorFn = (req, next) => {
  const { url, method } = req;

  if (url.includes("/customer") && method === "GET") {
    const res = {
      availableFilters: [0, 1, 2, 3, 4],
    };

    return of(
      new HttpResponse<Customer>({
        status: 200,
        body: res,
      })
    ).pipe(
      catchError(() =>
        of(
          new HttpResponse<Customer>({
            status: 500,
            body: undefined,
          })
        )
      )
    );
  }

  return next(req);
};
