import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  OnInit,
  signal,
  untracked,
  ViewChild,
} from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDrawer } from "@angular/material/sidenav";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { ActivityGridOptionsComponent } from "./activity-grid-options/activity-grid-options.component";
import { Column } from "@src/activity/models";
import { LoadingRippleComponent } from "@shared/components";
import { RecipientsFormatterPipe } from "./pipes/recipientsFormatter.pipe";
import { ActivityGridNoResultsComponent } from "./activity-grid-no-results/activity-grid-no-results.component";
import { GridService, GridState } from "./services";

@Component({
  selector: "ath-activity-grid",
  standalone: true,
  imports: [
    ActivityGridNoResultsComponent,
    ActivityGridOptionsComponent,
    CdkDrag,
    CdkDropList,
    DatePipe,
    LoadingRippleComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatToolbarModule,
    RecipientsFormatterPipe,
    RouterModule,
  ],
  templateUrl: "./activity-grid.component.html",
  styleUrl: "./activity-grid.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityGridComponent implements OnInit {
  readonly GridState = GridState;

  recordsCollection = this.gridService.records;
  gridState = this.gridService.gridState;
  pageSizeOptions = [25, 50, 100];
  drawer?: MatDrawer;

  // Determines the column order and which columns are displayed
  columns = signal<Column[]>([
    { key: "kind", displayName: "Type", active: true },
    { key: "source", displayName: "Source", active: true },
    { key: "sender", displayName: "From", active: true },
    { key: "recipients", displayName: "To", active: true },
    { key: "callStart", displayName: "Call start", active: true },
    { key: "duration", displayName: "Duration", active: true },
    { key: "account", displayName: "User", active: true },
    { key: "actions", displayName: "Actions", active: true, static: true },
  ]);

  displayedColumns = computed(() =>
    this.columns()
      .filter((x) => x.active)
      .map((x) => x.key)
  );

  @ViewChild("table", { read: ElementRef }) table?: ElementRef<HTMLTableElement>;

  constructor(private gridService: GridService) {
    effect(() => {
      this.recordsCollection();

      untracked(() => {
        this.table?.nativeElement.scrollIntoView({ behavior: "smooth" });
      });
    });
  }

  ngOnInit(): void {
    this.gridService.refreshData();
  }

  openFilterDrawer(): void {
    void this.gridService.filterDrawer?.open();
  }

  reorderColumn(event: CdkDragDrop<string[]>): void {
    if (this.columns()[event.currentIndex].static) {
      return;
    }

    this.columns.update((columns) => {
      const rearrange = [...columns];
      moveItemInArray(rearrange, event.previousIndex, event.currentIndex);
      return rearrange;
    });
  }

  updateColumns(newColumns: Column[]): void {
    this.columns.set(newColumns);
  }

  paginatorUpdated(event: PageEvent): void {
    this.gridService.updatePagination(event);
  }
}
