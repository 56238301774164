import { ParticipantsFilterComponent } from "./participants-filter/participants-filter.component";
import { DateFilterComponent } from "./date-filter/date-filter.component";
import { UsersFilterComponent } from "./users-filter/users-filter.component";
import { SourceFilterComponent } from "./source-filter/source-filter.component";
import { RecordingTypeFilterComponent } from "./recording-type-filter/recording-type-filter.component";
import { Filter } from "../models";

export const FILTERS: Filter[] = [
  {
    id: 0,
    active: false,
    required: true,
    name: "Date and time",
    component: DateFilterComponent,
  },
  {
    id: 1,
    active: false,
    required: true,
    name: "Recording type",
    valueEndpoint: "/v2/recording-kinds",
    component: RecordingTypeFilterComponent,
  },
  {
    id: 2,
    active: true,
    required: false,
    name: "Sources",
    valueEndpoint: "/v1/source-groups",
    component: SourceFilterComponent,
  },
  {
    id: 3,
    active: true,
    required: false,
    name: "Users",
    valueEndpoint: "/v1/persons",
    component: UsersFilterComponent,
  },
  {
    id: 4,
    active: true,
    required: false,
    name: "Participants",
    valueEndpoint: "/v1/accounts",
    component: ParticipantsFilterComponent,
  },
];
