<h1>List of records</h1>

<h2 class="secondary-title">
  {{ secondaryTitleValue() }}
</h2>

<mat-chip-set aria-label="Active filters">
  @for (tc of templateContexts(); track $index) {
    <mat-chip disableRipple
      ><ng-container *ngTemplateOutlet="tc.template; context: tc.context"></ng-container
    ></mat-chip>
  }

  <button mat-flat-button (click)="openFilterDrawer()">
    <mat-icon svgIcon="filter-alt" />
    Open filters
  </button>
</mat-chip-set>
