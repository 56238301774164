import { Routes } from "@angular/router";
import { ActivityComponent } from "../activity/activity.component";
import { MsalGuard } from "@azure/msal-angular";
import { FailedComponent, LoggedOutComponent } from "./auth";

export const routes: Routes = [
  { path: "", redirectTo: "activity", pathMatch: "full" },
  {
    path: "activity",
    title: "Activity",
    component: ActivityComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "activity/:recordingId",
    title: "Record Viewer",
    loadComponent: () =>
      import("../activity/record-viewer/record-viewer.component").then(
        (m) => m.RecordViewerComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: "audit",
    title: "Audit",
    loadComponent: () =>
      import("../audit-log/audit-log.component").then((m) => m.AuditLogComponent),
    canActivate: [MsalGuard],
  },
  {
    path: "login-failed",
    component: FailedComponent,
  },
  {
    path: "logged-out",
    component: LoggedOutComponent,
  },
  {
    path: "**",
    canActivate: [MsalGuard],
    component: ActivityComponent,
  },
];
