<nav #nav class="nav">
  @if (!isExpanded()) {
    <img class="logo" src="assets/logos/touch_t_only.svg" alt="Touch logo" />
  } @else {
    <img class="logo-expanded" src="assets/logos/touch_full.svg" alt="Touch logo" />
  }

  <div class="nav-content">
    @for (item of navItems; track item.name) {
      <a
        class="nav-link"
        [routerLink]="item.route"
        routerLinkActive="nav-active"
        [routerLinkActiveOptions]="activeOptions"
        [ariaLabel]="item.name"
      >
        <mat-icon [svgIcon]="item.icon" />
        @if (isExpanded()) {
          <span>{{ item.name }}</span>
        }
      </a>
    }
  </div>

  <span class="divider"></span>

  <div class="nav-content">
    @for (item of navItems2; track item.name) {
      <a
        class="nav-link"
        [routerLink]="item.route"
        routerLinkActive="nav-active"
        [routerLinkActiveOptions]="activeOptions"
        [ariaLabel]="item.name"
      >
        <mat-icon [svgIcon]="item.icon" />
        @if (isExpanded()) {
          <span>{{ item.name }}</span>
        }
      </a>
    }
  </div>

  <div class="nav-footer">
    <div class="nav-expand" [class.expanded]="isExpanded()">
      <button
        mat-icon-button
        (click)="toggleExpand()"
        [matTooltip]="isExpanded() ? 'Collapse sidebar' : 'Expand sidebar'"
      >
        <mat-icon svgIcon="double-arrow" />
      </button>
    </div>

    <div class="nav-logout">
      <button matTooltip="Sign out" type="button" mat-icon-button (click)="initiateLogout()">
        <mat-icon svgIcon="logout" />
      </button>
    </div>
  </div>
</nav>
