<div id="grid-view-wrapper">
  <mat-drawer-container hasBackdrop="true">
    <mat-drawer #drawer position="end">
      <ath-activity-filter cdkTrapFocus [cdkTrapFocusAutoCapture]="true" />
    </mat-drawer>
    <mat-drawer-content>
      @if (gridState() === GridState.NoActiveFilters) {
        <ath-no-active-filters />
      } @else {
        <div class="main-content">
          <ath-activity-header />
          <ath-activity-grid />
        </div>
      }
    </mat-drawer-content>
  </mat-drawer-container>
</div>
