import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterOutlet } from "@angular/router";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NavbarComponent } from "@features/navbar/navbar.component";
import { AuthService } from "@shared/services";
import { IconNames } from "@shared/constants";

@Component({
  selector: "ath-root",
  imports: [CommonModule, RouterOutlet, NavbarComponent, MatIconModule],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  private readonly iconsPath = "/assets/icons/";

  isAuthenticated = this.authService.isAuthenticated;

  constructor(
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    IconNames.forEach((icon) => {
      this.iconRegistry.addSvgIcon(icon, this.getIconPath(icon));
    });
  }

  private getIconPath(name: string): SafeResourceUrl {
    const iconPath = `${this.iconsPath}${name}.svg`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath);
  }
}
