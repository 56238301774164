import { ApplicationConfig, ErrorHandler } from "@angular/core";
import {
  RouteReuseStrategy,
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withViewTransitions,
} from "@angular/router";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { provideAnimations } from "@angular/platform-browser/animations";
import { routes } from "./app.routes";
import {
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from "@azure/msal-angular";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MsalInterceptorConfigFactory,
} from "./msal-factories";
import { PageTitleStrategy, ReuseStrategy } from "./route-strategies";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { nb } from "date-fns/locale";
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
  provideDateFnsAdapter,
} from "@angular/material-date-fns-adapter";
import { CustomMsalInterceptor } from "./interceptors/custom-msal-interceptor";
import { httpErrorLoggingInterceptor } from "./interceptors/http/http-error-logging.interceptor";
import { GlobalErrorHandlerService } from "./services/error/global-error-handler.service";

const FORM_OPTIONS = {
  appearance: "outline",
  floatLabel: "always",
  subscriptSizing: "dynamic",
};

const TOOLTIP_OPTIONS = {
  showDelay: 500,
  hideDelay: 200,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
      withInterceptors([httpErrorLoggingInterceptor])
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomMsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideAnimations(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { ...FORM_OPTIONS } },
    { provide: RouteReuseStrategy, useClass: ReuseStrategy },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    { provide: MAT_DATE_LOCALE, useValue: nb },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TOOLTIP_OPTIONS },
    provideDateFnsAdapter(),
  ],
};
